import { combineReducers } from "@reduxjs/toolkit";
import authReducer, { cleanupUserSettings } from "./slice/auth-slice"; // Import logout from the slice
import appointmentReducer from "./slice/appointment-slice";
import treatmentReducer from "./slice/treatment-slice";
import prescriptionReducer from "./slice/prescriptions-slice";
import labsReducer from "./slice/labs-slice";
import paymentReducer from "./slice/payment-slice";
import transactionReducer from "./slice/transaction-history-slice";
import expenseReducer from "./slice/expense";
import hospitalInfoReducer from "./slice/hospital-info-slice";
import analyticsReducer from "./slice/analytics-slice";

const appReducer = combineReducers({
  auth: authReducer,
  appointment: appointmentReducer,
  treatment: treatmentReducer,
  prescription: prescriptionReducer,
  labs: labsReducer,
  payment: paymentReducer,
  transaction: transactionReducer,
  expense: expenseReducer,
  hospitalInfo: hospitalInfoReducer,
  analytics: analyticsReducer,
});

const rootReducer = (state, action) => {
  // Reset state if the logout action is dispatched
  if (action.type === cleanupUserSettings.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
